import { configValues } from "../config-values";

export const Colors = {
  white: "white",
  primary: configValues.project.primaryColor,
  fontPrimary: configValues.project.fontPrimaryColor,
  fontInfo: configValues.project.fontInfo,
  fontError: "#f01d1d",
  errorBg: "red",
  bg: configValues.project.primaryBg,
  secondaryBg: configValues.project.secondaryBg,
  btnSecondary: "rgba(34, 34, 34, 0.2)",
  waterBlue: "rgb(2, 122, 255)",
  waterBorder: (size = 1) => `${size}px solid rgb(2, 122, 255)`,
  primaryBorder: (size = 1) =>
    `${size}px solid ${configValues.project.primaryColor}`,
};
