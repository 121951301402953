import { FC } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { BackSvg } from "../../assets/svg/BackSvg";
import { Colors } from "../../styles/global";
import { configValues } from "../../config-values";

const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  position: fixed;
  flex-direction: column;
  box-shadow: ${configValues.project.styles.content.backHeadeBoxShadow};
  background: ${configValues.project.styles.content.userContentBackground};
`;

const TopContent = styled.div`
  display: flex;
  padding: 1rem;
  padding-bottom: 8px;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: ${configValues.project.styles.content.cardTitleColor};
  margin: 0;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 600;
`;

const BackButton = styled.button`
  top: 50%;
  left: 1rem;
  border: none;
  cursor: pointer;
  background: none;
  position: absolute;
  transform: translateY(-35%);
  color: ${configValues.project.styles.content.cardTitleColor};
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Tab = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  color: ${configValues.project.styles.content.cardTextColor};
  border-bottom: 3px solid transparent;

  &.active {
    color: ${configValues.project.styles.content.cardTitleColor};
    border-bottom-color: ${configValues.project.styles.content.cardTitleColor};
  }
`;

interface Props {
  title: string;
  active: string;
  onTabChange: (tab: string) => void;
}

export const ChangeMealHeader: FC<Props> = ({ title, active, onTabChange }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <TopContent>
        <BackButton onClick={() => navigate(-1)}>
          <BackSvg />
        </BackButton>
        <Title>{title}</Title>
      </TopContent>
      <TabsContainer>
        <Tab
          onClick={() => onTabChange("recommended")}
          className={active === "recommended" ? "active" : ""}
        >
          Recommended
        </Tab>
        <Tab
          onClick={() => onTabChange("favorites")}
          className={active === "favorites" ? "active" : ""}
        >
          Favorites
        </Tab>
      </TabsContainer>
    </Container>
  );
};
