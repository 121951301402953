import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { MealsSvg } from "../../assets/svg/MealsSvg";
import { ShoppingSvg } from "../../assets/svg/ShoppingSvg";
import { ProfileSvg } from "../../assets/svg/ProfileSvg";
import { ExploreSvg } from "../../assets/svg/ExploreSvg";
import { useShoppingList } from "../../state/shoppingList";
import { Colors } from "../../styles/global";
import { useMealPlan } from "../../state/mealPlan";
import { useEffect } from "react";
import { useProfile } from "../../state/profile";
import { WorkoutsTab } from "./WorkoutsTab";

const TabBarContainer = styled.nav`
  z-index: 99;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f8f8f8;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;

const Tab = styled(Link)`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  flex-direction: column;
  color: rgba(34, 34, 34, 0.4);
  font-size: 14px;

  &.active {
    color: ${Colors.primary};
  }

  svg {
    margin-bottom: 5px;
  }
`;

export const TabBar = () => {
  const location = useLocation();
  const { profile, refetchUser } = useProfile();
  const { mealPlan, fetchMealPlan } = useMealPlan();
  const { shoppingList, fetchShoppingList } = useShoppingList();
  const isActive = (path: string) => location.pathname === path;
  const includes = (str: string) => location.pathname.includes(str);
  const mealsPath =
    mealPlan?.status === "ready" ? "/meals/plan" : "/meals/initial";
  const shoppingPath =
    shoppingList?.status === "ready" ? "/shopping/list" : "/shopping/initial";

  useEffect(() => {
    const route = location.pathname;
    refetchUser();
    if (route.includes("/meals")) {
      fetchMealPlan(profile.mealPlanId);
    }
    if (route.includes("/shopping")) {
      fetchShoppingList(profile.shoppingListId);
    }
  }, [location, profile, refetchUser, fetchMealPlan, fetchShoppingList]);

  return (
    <TabBarContainer>
      <Tab to="/explore" className={isActive("/explore") ? "active" : ""}>
        <ExploreSvg />
        <span>Explore</span>
      </Tab>
      <WorkoutsTab />
      <Tab to={mealsPath} className={includes("/meals") ? "active" : ""}>
        <MealsSvg />
        <span>Meals</span>
      </Tab>
      <Tab to={shoppingPath} className={includes("/shopping") ? "active" : ""}>
        <ShoppingSvg />
        <span>Shopping</span>
      </Tab>
      <Tab to="/profile" className={isActive("/profile") ? "active" : ""}>
        <ProfileSvg />
        <span>Profile</span>
      </Tab>
    </TabBarContainer>
  );
};
