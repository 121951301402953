import { FC } from "react";
import styled from "styled-components";
import { configValues } from "../../config-values";

const Container = styled.div`
  gap: 1rem;
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  background: ${configValues.project.styles.content.cardBackground};
  border: 0.0625rem solid ${configValues.project.styles.content.cardBorderColor};
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${configValues.project.styles.content.cardShadow};
`;

const Header = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.h2`
  font-size: 24px;
  color: ${configValues.project.styles.content.cardTitleColor};
  margin: 0;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.2);
`;

export const SourcesCard: FC = () => {
  if (!configValues.project.inReview) {
    return <></>;
  }

  return (
    <Container>
      <Header>
        <Title>Science that is backing our product</Title>
      </Header>
      <Divider />
      <ol
        style={{
          color: configValues.project.styles.content.cardTextColor,
        }}
      >
        {configValues.project.sources.map((source, index) => (
          <li key={`bullshit-generated-source-${index}`}>
            <a
              href={source.url}
              target="_blank"
              rel="noreferrer"
              style={{
                color: configValues.project.styles.content.cardTextColor,
              }}
            >
              {source.title}
            </a>
          </li>
        ))}
      </ol>
    </Container>
  );
};
