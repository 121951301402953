import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { configValues } from "../../config-values";

const Container = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
`;

const Title = styled.span<{ $color: string }>`
  color: black;
  font-size: 1rem;
  text-decoration: none;
  color: ${(p) =>
    p.$color ?? configValues.project.styles.content.cardTitleColor};
`;

const Detail = styled.span<{ purchased?: boolean }>`
  margin: 0;
  color: ${configValues.project.styles.content.cardTextColor};
  font-size: 14px;
`;

const Right = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
`;

interface Props {
  icon?: any;
  link?: string;
  title: string;
  selection?: string;
  onClick?: () => void;
  color?: string;
}

export const SettingsItem: FC<Props> = ({
  icon,
  link,
  title,
  onClick,
  selection,
  color,
}) => {
  if (link) {
    return (
      <Link to={link} style={{ textDecoration: "none" }}>
        <Container>
          <Title $color={color}>{title}</Title>
          <Right>
            {selection && <Detail>{selection}</Detail>}
            {icon && icon}
          </Right>
        </Container>
      </Link>
    );
  }
  return (
    <Container onClick={onClick}>
      <Title $color={color}>{title}</Title>
      <Right>
        {selection && <Detail>{selection}</Detail>}
        {icon && icon}
      </Right>
    </Container>
  );
};
