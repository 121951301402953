import styled from "styled-components";
import { MealsHeader } from "../../components/nav/MealsHeader";
import { TabBar } from "../../components/nav/TabBar";
import { useEffect, useState } from "react";
import { useMealPlan } from "../../state/mealPlan";
import { CarouselItem } from "../../components/items/CarouselItem";
import { format } from "date-fns";
import { useProfile } from "../../state/profile";
import { configValues } from "../../config-values";

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 0 1rem;
  padding-top: 110px;
  flex-direction: column;
  padding-bottom: 80px;
  background: ${configValues.project.styles.content.userContentBackground};
`;

export const MealPlanView = () => {
  const { profile } = useProfile();
  const [day, setDay] = useState(format(new Date(), "EEEE").toLowerCase());
  const { getDayMeals, fetchMealPlan, setCurrentWeek } = useMealPlan();
  const todaysMeals = getDayMeals(day);

  useEffect(() => {
    setCurrentWeek();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!todaysMeals && profile.mealPlanId) {
      fetchMealPlan(profile.mealPlanId);
    }
  }, [profile, todaysMeals, fetchMealPlan]);

  return (
    <>
      <MealsHeader active={day} onTabChange={setDay} />
      <Content>
        {todaysMeals && (
          <>
            <CarouselItem
              day={day}
              title="Breakfast"
              mealType="breakfast"
              mealIds={todaysMeals.breakfast}
            />
            <CarouselItem
              day={day}
              title="Morning snack"
              mealType="snack"
              mealIds={[todaysMeals.snack[0]]}
            />
            <CarouselItem
              day={day}
              title="Lunch"
              mealType="lunch"
              mealIds={todaysMeals.lunch}
            />
            <CarouselItem
              day={day}
              title="Afternoon snack"
              mealType="snack"
              mealIds={[todaysMeals.snack[1]]}
            />
            <CarouselItem
              day={day}
              title="Dinner"
              mealType="dinner"
              mealIds={todaysMeals.dinner}
            />
          </>
        )}
      </Content>
      <TabBar />
    </>
  );
};
