import { ReactNode, PropsWithChildren, FC } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/global";
import { SettingsSvg } from "../../assets/svg/SettingsSvg";
import { configValues } from "../../config-values";

const Container = styled.div`
  gap: 1rem;
  display: flex;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  background: ${configValues.project.styles.content.cardBackground};
  border: 0.0625rem solid ${configValues.project.styles.content.cardBorderColor};
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${configValues.project.styles.content.cardShadow};
`;

const Header = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.h2`
  font-size: 24px;
  color: ${configValues.project.styles.content.cardTitleColor};
  margin: 0;
`;

const Text = styled.p`
  margin: 0;
  padding: 0 0.25rem;
  font-size: 16px;
  line-height: 24px;
  color: ${configValues.project.styles.content.cardTextColor};
`;

const Icon = styled.div`
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.primary};
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.2);
`;

interface Props {
  title: string;
  icon: ReactNode;
  text: string | ReactNode;
  disclaimer?: string | ReactNode;
  onSettingsClick?: () => void;
}

export const GoalCard: FC<PropsWithChildren<Props>> = ({
  icon,
  text,
  title,
  onSettingsClick: onClick,
  children,
}) => (
  <Container>
    <Header>
      {icon}
      <Title>{title}</Title>
      {onClick && (
        <Icon onClick={onClick}>
          <SettingsSvg />
        </Icon>
      )}
    </Header>
    <Divider />
    <Text>{text}</Text>
    {children}
  </Container>
);
