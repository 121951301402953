import styled from "styled-components";
import { TabBar } from "../../components/nav/TabBar";
import { useEffect } from "react";
import { useProfile } from "../../state/profile";
import { useWorkoutPlan } from "../../state/workoutPlan";
import { WorkoutCarouselItem } from "../../components/items/WorkoutCarouselItem";
import { configValues } from "../../config-values";
import { WorkoutsTopContent } from "../../components/nav/WorkoutsTopContent";

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 0 1rem;
  padding-top: 16px;
  flex-direction: column;
  padding-bottom: 80px;
  background: ${configValues.project.styles.content.userContentBackground};
`;

export const Workouts = () => {
  const { profile } = useProfile();
  const { allWorkouts, fetchWorkoutPlan } = useWorkoutPlan();
  const gender =
    JSON.parse(localStorage.getItem("profile") ?? "{}")?.state?.profile
      ?.quizData?.gender ?? "female";

  useEffect(() => {
    fetchWorkoutPlan(profile.workoutPlanId, gender);
  }, [profile, fetchWorkoutPlan, allWorkouts, gender]);

  return (
    <>
      <Content>
        <WorkoutsTopContent />
        <div style={{ height: "20px" }} />
        {allWorkouts?.map((workout, index) => (
          <WorkoutCarouselItem
            key={`workout-item-${index}`}
            id={workout.workoutId}
          />
        ))}
      </Content>
      <TabBar />
    </>
  );
};
