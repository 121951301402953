import { FC } from "react";
import Chart, { Props as ApexOptions } from "react-apexcharts";
import { Colors } from "../../styles/global";
import styled from "styled-components";
import { configValues } from "../../config-values";

let options = {
  chart: {
    type: "area",
    height: 300,
    foreColor: configValues.project.styles.content.cardTextColor,
    animations: {
      speed: 500,
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    colors: [configValues.project.styles.content.caloriesGraphColor],
  },
  fill: {
    shadeIntensity: 1,
    opacityFrom: 0.7,
    opacityTo: 0.9,
    stops: [0, 90, 100],
    colors: [configValues.project.styles.content.caloriesGraphColor],
  },
  grid: {
    padding: {
      right: 24, // Adjust this value as needed
    },
  },
  tooltip: {
    x: {
      format: "MMM d",
    },
  },
  annotations: {
    yaxis: [
      {
        y: 85,
        borderWidth: 2,
        strokeDashArray: 0,
        borderColor: configValues.project.styles.content.caloriesGraphColor,
        label: {
          text: "Goal",
          borderColor: configValues.project.styles.content.caloriesGraphColor,
          style: {
            color: configValues.project.styles.content.cardTextColor,
            background: configValues.project.styles.content.caloriesGraphColor,
          },
        },
      },
    ],
  },
} as ApexOptions["options"];

const Container = styled.div`
  flex: 1;
  display: flex;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 12px;
  background: #ffffff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${configValues.project.styles.content.cardBackground};
  border: 0.0625rem solid ${configValues.project.styles.content.cardBorderColor};
  box-shadow: ${configValues.project.styles.content.cardShadow};
`;

const Button = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${configValues.project.styles.button.fontColor};
  background: ${configValues.project.styles.button.background};
`;

interface Props {
  goal: number;
  units: "metric" | "imperial";
  weights: number[];
  datetime: string[];
  onClick: () => void;
}

export const WeightGraph: FC<Props> = ({
  goal,
  units,
  weights,
  datetime,
  onClick,
}) => {
  options.annotations.yaxis[0].y = goal;
  options = {
    ...options,
    yaxis: {
      min: goal - 10,
      labels: {
        formatter: (value: number) =>
          `${Math.floor(value)} ${units === "metric" ? "kg" : "lbs"}`,
      },
    },
  };

  const xaxis: ApexXAxis = {
    type: "datetime",
    categories: datetime,
    stepSize: 1,
    labels: {
      minHeight: 40,
      format: "MMM d",
      rotateAlways: true,
      showDuplicates: false,
    },
  };
  const series: ApexOptions["series"] = [
    {
      name: "Weight",
      data: weights,
    },
  ];

  return (
    <Container>
      <div id="chart" style={{ width: "100%" }}>
        <Chart
          type="area"
          height={300}
          series={series}
          options={{
            ...options,
            xaxis,
          }}
        />
      </div>
      <Button onClick={onClick}>Track weight</Button>
    </Container>
  );
};
