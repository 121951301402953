import { useEffect, useState } from "react";
import { TabBar } from "../../components/nav/TabBar";
import { WaterSheet } from "../../components/sheets/WaterSheet";
import { GoalCard } from "../../components/cards/GoalCard";
import styled from "styled-components";
import { WaterSvg } from "../../assets/svg/WaterSvg";
import { Colors } from "../../styles/global";
import { GoalGraph } from "../../components/graphs/GoalGraph";
import { WeightSvg } from "../../assets/svg/WeightSvg";
import { WeightGraph } from "../../components/graphs/WeightGraph";
import { useProfile } from "../../state/profile";
import { WeightSheet } from "../../components/sheets/WeightSheet";
import { getCurrentDate } from "../../utils/datetime";
import { getWeightGoalText } from "../../utils/weight";
import { InfoSvg } from "../../assets/svg/InfoSvg";
import { TrackWaterSheet } from "../../components/sheets/TrackWaterSheet";
import { TrackWaterSheetImperial } from "../../components/sheets/TrackWaterSheetImperial";
import { WaterSheetImperial } from "../../components/sheets/WaterSheetImperial";
import { convertMlToOunces } from "../../utils/units";
import { Api } from "../../apis/api";
import { SourcesCard } from "../../components/cards/SourcesCard";
import { useWorkoutPlan } from "../../state/workoutPlan";
import { IWorkout } from "../../types/workoutplan";
import { configValues } from "../../config-values";
import { DayWorkoutCard } from "../../components/cards/DayWorkoutCard";

const Content = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  padding-bottom: 90px;
  background: ${configValues.project.styles.content.userContentBackground};
`;

const Button = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${configValues.project.styles.content.waterButtonTextColor};
  background: ${configValues.project.styles.content.waterButtonBackground};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    color: #e3e3e3;
  }
`;

const Disclaimer = styled.div`
  font-size: 14px;
  max-width: 92%;
  color: ${configValues.project.styles.content.cardTextColor};
  margin: 0;

  ul,
  ol {
    padding: 0;
  }
`;

export const ExploreView = () => {
  const { profile, update, getWeightGraph } = useProfile();
  const [loading, setLoading] = useState(false);
  const [isSheetOpen, setSheetOpen] = useState(false);
  const [isWeightOpen, setWeightOpen] = useState(false);
  const [isGoalOpen, setGoalOpen] = useState(false);
  const [isTrackWaterOpen, setTrackWaterOpen] = useState(false);
  const { allWorkouts, fetchWorkoutPlan } = useWorkoutPlan();
  const gender =
    JSON.parse(localStorage.getItem("profile") ?? "{}")?.state?.profile
      ?.quizData?.gender ?? "female";

  useEffect(() => {
    fetchWorkoutPlan(profile.workoutPlanId, gender);
  }, [profile, fetchWorkoutPlan, allWorkouts, gender]);

  const onWaterSave = async (intake: number) => {
    try {
      setLoading(true);
      await update({
        ...profile,
        waterIntake: intake,
      });
      setSheetOpen(false);
    } catch (e) {
      Api.reportError(e, {
        fn: "onWaterSave",
        email: profile.email,
      });
    } finally {
      setLoading(false);
    }
  };

  const onWeightSave = async (weight: number) => {
    try {
      setLoading(true);
      await update({
        ...profile,
        weightTracked: {
          ...profile.weightTracked,
          [getCurrentDate()]: weight,
        },
      });
      setWeightOpen(false);
    } catch (e) {
      Api.reportError(e, {
        fn: "onWeightSave",
        email: profile.email,
      });
    } finally {
      setLoading(false);
    }
  };

  const onGoalSave = async (goal: number) => {
    try {
      setLoading(true);
      await update({
        ...profile,
        goal,
      });
      setGoalOpen(false);
    } catch (e) {
      Api.reportError(e, {
        fn: "onGoalSave",
        email: profile.email,
      });
    } finally {
      setLoading(false);
    }
  };

  const onWaterTracked = async (ml: number) => {
    try {
      setLoading(true);
      const water = profile.waterConsumed || {};
      const date = getCurrentDate();
      await update({
        ...profile,
        waterConsumed: {
          ...water,
          [date]: (water[date] || 0) + ml,
        },
      });
      setTrackWaterOpen(false);
    } catch (e) {
      Api.reportError(e, {
        fn: "onWaterTracked",
        email: profile.email,
      });
    } finally {
      setLoading(false);
    }
  };

  const onWaterTrack = () => {
    if (profile.waterIntake) {
      setTrackWaterOpen(true);
    } else {
      setSheetOpen(true);
    }
  };

  const { datetime, goal, latestTracked, weight, weights } = getWeightGraph();
  const text = getWeightGoalText(weight, latestTracked, goal, profile.units);
  const WaterText = profile.waterIntake ? (
    <span>
      Your daily water intake goal is{" "}
      <b style={{ color: configValues.project.styles.content.textMarkedColor }}>
        {Math.floor(profile.waterIntake)} ml
      </b>{" "}
      of water every day.
    </span>
  ) : (
    <b>
      Set your daily water intake goal. We recommend to drink at least{" "}
      <span style={{ color: Colors.waterBlue }}>2000 ml</span> of water every
      day.
    </b>
  );

  const dayWorkout: IWorkout | undefined =
    allWorkouts.length > 0
      ? allWorkouts[new Date().getDate() % allWorkouts.length]
      : undefined;

  return (
    <>
      <Content>
        <GoalGraph />
        <WeightGraph
          goal={goal}
          weights={weights}
          datetime={datetime}
          units={profile.units}
          onClick={() => setWeightOpen(true)}
        />
        <GoalCard
          text={text}
          icon={<WeightSvg />}
          title="Weight Goal"
          onSettingsClick={() => setGoalOpen(true)}
        />
        {dayWorkout && <DayWorkoutCard workoutId={dayWorkout.workoutId} />}
        <GoalCard
          text={WaterText}
          icon={<WaterSvg />}
          title="Daily water Goal"
          onSettingsClick={() => setSheetOpen(true)}
        >
          <Row>
            <InfoSvg />
            <Disclaimer>
              <span>
                🚰 The U.S. National Academies of Sciences, Engineering, and
                Medicine determined that an adequate daily fluid intake is:
              </span>
              <ul>
                <li>About 15.5 cups (3.7 liters) of fluids a day for men.</li>
                <li>About 11.5 cups (2.7 liters) of fluids a day for women.</li>
              </ul>
            </Disclaimer>
          </Row>
          <Button onClick={onWaterTrack}>Track water</Button>
        </GoalCard>
        <SourcesCard />
      </Content>
      <WeightSheet
        title="Set your target weight"
        loading={loading}
        onSave={onGoalSave}
        isOpen={isGoalOpen}
        currentWeight={profile.goal}
        onClose={() => setGoalOpen(false)}
      />
      <WeightSheet
        title="Track your current weight"
        loading={loading}
        onSave={onWeightSave}
        isOpen={isWeightOpen}
        currentWeight={profile.weight}
        onClose={() => setWeightOpen(false)}
      />
      {profile.units === "metric" ? (
        <>
          <WaterSheet
            loading={loading}
            onSave={onWaterSave}
            isOpen={isSheetOpen}
            currentIntake={profile.waterIntake}
            onClose={() => setSheetOpen(false)}
          />
          <TrackWaterSheet
            loading={loading}
            onSave={onWaterTracked}
            isOpen={isTrackWaterOpen}
            onClose={() => setTrackWaterOpen(false)}
          />
        </>
      ) : (
        <>
          <WaterSheetImperial
            loading={loading}
            onSave={onWaterSave}
            isOpen={isSheetOpen}
            onClose={() => setSheetOpen(false)}
            currentIntake={convertMlToOunces(profile.waterIntake)}
          />
          <TrackWaterSheetImperial
            loading={loading}
            onSave={onWaterTracked}
            isOpen={isTrackWaterOpen}
            onClose={() => setTrackWaterOpen(false)}
          />
        </>
      )}
      <TabBar />
    </>
  );
};
