import { IWorkout } from "../types/workoutplan";

export const hypnosisPlanResponse: Record<string, IWorkout[]> = {
  female: [
    {
      workoutId: "0",
      title: "Mindset and Motivation for Weight Loss",
      image: "hypnosis/1.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Reframing Your Weight Loss Journey",
          duration: 7,
          instructions: [
            "Find a comfortable position, either sitting or lying down.",
            "Close your eyes gently, releasing any tension.",
            "This is your time, a safe space to connect with yourself and your journey.",
          ],
          audio: "hypnosis/11Reframing.mp3",
        },
        {
          id: "1",
          title: "Strengthening Willpower and Determination",
          duration: 6,
          instructions: [
            "Settle into a comfortable position, allowing relaxation.",
            "Close your eyes gently and take deep breaths.",
            "Release any tension as you prepare to reinforce your willpower.",
          ],
          audio: "hypnosis/12Strengthening.mp3",
        },
        {
          id: "2",
          title: "Visualization for Success and Confidence",
          duration: 6,
          instructions: [
            "Find a comfortable position and close your eyes gently.",
            "Allow yourself to take deep, calming breaths.",
            "Relax into this time, letting go of any tension.",
          ],
          audio: "hypnosis/13visualize.mp3",
        },
        {
          id: "3",
          title: "Building Self-Compassion and Body Appreciation",
          duration: 7,
          instructions: [
            "Find a comfortable position, either sitting or lying down.",
            "Close your eyes, take deep breaths, releasing any stress.",
            "Allow yourself to connect with self-kindness and appreciation.",
          ],
          audio: "hypnosis/14building.mp3",
        },
      ],
    },
    {
      workoutId: "1",
      title: "Cravings and Emotional Eating Control",
      image: "hypnosis/2.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Overcoming Sugar and Junk Food Cravings",
          duration: 7,
          instructions: [
            "Find a comfortable position, either sitting or lying down.",
            "Close your eyes gently and allow calmness to settle in.",
            "Prepare to release cravings as you connect with your strength.",
          ],
          audio: "hypnosis/21overcoming.mp3",
        },
        {
          id: "1",
          title: "Redirecting Emotional Eating Patterns",
          duration: 8,
          instructions: [
            "Sit back or lay down comfortably, releasing any stress.",
            "Take slow breaths, creating a calm, safe space within.",
            "Prepare to gently transform how you respond to emotions.",
          ],
          audio: "hypnosis/22redirect.mp3",
        },
        {
          id: "2",
          title: "Developing Healthy Coping Mechanisms for Stress",
          duration: 7,
          instructions: [
            "Settle into a relaxing position and close your eyes.",
            "Breathe in calmness, breathe out any lingering tension.",
            "Prepare to cultivate peaceful, healthy responses to stress.",
          ],
          audio: "hypnosis/23develope.mp3",
        },
        {
          id: "3",
          title: "Mindful Eating and Awareness of Fullness",
          duration: 6,
          instructions: [
            "Find a comfortable position and close your eyes gently.",
            "Allow yourself to relax and bring your awareness inward.",
            "Prepare to connect with your body’s natural hunger signals.",
          ],
          audio: "hypnosis/24mindful.mp3",
        },
      ],
    },
    {
      workoutId: "2",
      title: "Healthy Eating Habits and Food Choices",
      image: "hypnosis/3.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Choosing Nutritious Foods Over Processed Options",
          duration: 7,
          instructions: [
            "Find a relaxing position, either sitting or lying down.",
            "Breathe deeply, releasing any tension with each exhale.",
            "Allow yourself to connect with the joy of nourishing foods.",
          ],
          audio: "hypnosis/31choosing.mp3",
        },
        {
          id: "1",
          title: "Portion Control and Satisfaction with Smaller Servings",
          duration: 6,
          instructions: [
            "Settle comfortably and take deep breaths to calm your mind.",
            "Let go of any stress, tuning into your body’s natural signals.",
            "Prepare to feel satisfied and content with balanced portions.",
          ],
          audio: "hypnosis/32portion.mp3",
        },
        {
          id: "2",
          title: "Creating Positive Associations with Healthy Foods",
          duration: 7,
          instructions: [
            "Relax in a comfortable position, allowing your eyes to close.",
            "Breathe in deeply, exhaling tension with each slow breath.",
            "Prepare to embrace the pleasure of healthy, nourishing foods.",
          ],
          audio: "hypnosis/33creating.mp3",
        },
        {
          id: "3",
          title: "Visualizing Healthy Meal Choices",
          duration: 6,
          instructions: [
            "Sit back comfortably and gently close your eyes.",
            "Take slow, calming breaths, releasing all distractions.",
            "Visualize yourself feeling joy in making healthy choices.",
          ],
          audio: "hypnosis/34visualizing.mp3",
        },
      ],
    },
    {
      workoutId: "3",
      title: "Exercise and Physical Activity Motivation",
      image: "hypnosis/4.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Finding Joy and Motivation in Movement",
          duration: 7,
          instructions: [
            "Relax into a comfortable position and close your eyes.",
            "Take a deep breath, releasing any tension with your exhale.",
            "Allow yourself to connect with the joy of moving your body.",
          ],
          audio: "hypnosis/41finding.mp3",
        },
        {
          id: "1",
          title: "Visualizing an Active, Energized Self",
          duration: 7,
          instructions: [
            "Settle comfortably and allow your eyes to close gently.",
            "Take calming breaths, releasing any stress or tension.",
            "Prepare to visualize yourself feeling energized and active.",
          ],
          audio: "hypnosis/42energized.mp3",
        },
        {
          id: "2",
          title: "Overcoming Barriers to Exercise",
          duration: 7,
          instructions: [
            "Sit or lie in a comfortable position, relaxing fully.",
            "Close your eyes and take deep breaths, releasing stress.",
            "Allow yourself to connect with your inner strength to move.",
          ],
          audio: "hypnosis/43overcoming.mp3",
        },
        {
          id: "3",
          title: "Building Stamina and Enjoyment in Physical Activity",
          duration: 6,
          instructions: [
            "Sit back comfortably and close your eyes gently.",
            "Let each breath calm you, releasing any lingering stress.",
            "Prepare to experience the joy of growing strength and stamina.",
          ],
          audio: "hypnosis/44stamina.mp3",
        },
      ],
    },
    {
      workoutId: "4",
      title: "Long-Term Habit Reinforcement and Maintenance",
      image: "hypnosis/5.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Creating a Future Vision of the New You",
          duration: 7,
          instructions: [
            "Find a comfortable position and close your eyes.",
            "Take slow, calming breaths, relaxing with each exhale.",
            "Allow yourself to connect with a vision of your best self.",
          ],
          audio: "hypnosis/51creating.mp3",
        },
        {
          id: "1",
          title: "Staying Committed to Long-Term Health Goals",
          duration: 7,
          instructions: [
            "Settle into a comfortable position and close your eyes.",
            "Take deep breaths, releasing any stress or distractions.",
            "Prepare to connect with your commitment to lasting wellness.",
          ],
          audio: "hypnosis/52staying.mp3",
        },
        {
          id: "2",
          title: "Dealing with Setbacks and Returning to Routine",
          duration: 6,
          instructions: [
            "Relax comfortably and close your eyes gently.",
            "Breathe deeply, letting go of any self-doubt or worry.",
            "Prepare to handle setbacks with calm and resilience.",
          ],
          audio: "hypnosis/53setbacks.mp3",
        },
        {
          id: "3",
          title: "Continued Self-Motivation for Lifelong Health",
          duration: 6,
          instructions: [
            "Settle comfortably, allowing your eyes to close gently.",
            "Breathe in deeply, letting out tension with each exhale.",
            "Connect with your motivation for lasting health and happiness.",
          ],
          audio: "hypnosis/54continued.mp3",
        },
      ],
    },
  ],
  male: [
    {
      workoutId: "0",
      title: "Mindset and Motivation for Weight Loss",
      image: "hypnosis/1.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Reframing Your Weight Loss Journey",
          duration: 7,
          instructions: [
            "Find a comfortable position, either sitting or lying down.",
            "Close your eyes gently, releasing any tension.",
            "This is your time, a safe space to connect with yourself and your journey.",
          ],
          audio: "hypnosis/11Reframing.mp3",
        },
        {
          id: "1",
          title: "Strengthening Willpower and Determination",
          duration: 6,
          instructions: [
            "Settle into a comfortable position, allowing relaxation.",
            "Close your eyes gently and take deep breaths.",
            "Release any tension as you prepare to reinforce your willpower.",
          ],
          audio: "hypnosis/12Strengthening.mp3",
        },
        {
          id: "2",
          title: "Visualization for Success and Confidence",
          duration: 6,
          instructions: [
            "Find a comfortable position and close your eyes gently.",
            "Allow yourself to take deep, calming breaths.",
            "Relax into this time, letting go of any tension.",
          ],
          audio: "hypnosis/13visualize.mp3",
        },
        {
          id: "3",
          title: "Building Self-Compassion and Body Appreciation",
          duration: 7,
          instructions: [
            "Find a comfortable position, either sitting or lying down.",
            "Close your eyes, take deep breaths, releasing any stress.",
            "Allow yourself to connect with self-kindness and appreciation.",
          ],
          audio: "hypnosis/14building.mp3",
        },
      ],
    },
    {
      workoutId: "1",
      title: "Cravings and Emotional Eating Control",
      image: "hypnosis/2.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Overcoming Sugar and Junk Food Cravings",
          duration: 7,
          instructions: [
            "Find a comfortable position, either sitting or lying down.",
            "Close your eyes gently and allow calmness to settle in.",
            "Prepare to release cravings as you connect with your strength.",
          ],
          audio: "hypnosis/21overcoming.mp3",
        },
        {
          id: "1",
          title: "Redirecting Emotional Eating Patterns",
          duration: 8,
          instructions: [
            "Sit back or lay down comfortably, releasing any stress.",
            "Take slow breaths, creating a calm, safe space within.",
            "Prepare to gently transform how you respond to emotions.",
          ],
          audio: "hypnosis/22redirect.mp3",
        },
        {
          id: "2",
          title: "Developing Healthy Coping Mechanisms for Stress",
          duration: 7,
          instructions: [
            "Settle into a relaxing position and close your eyes.",
            "Breathe in calmness, breathe out any lingering tension.",
            "Prepare to cultivate peaceful, healthy responses to stress.",
          ],
          audio: "hypnosis/23develope.mp3",
        },
        {
          id: "3",
          title: "Mindful Eating and Awareness of Fullness",
          duration: 6,
          instructions: [
            "Find a comfortable position and close your eyes gently.",
            "Allow yourself to relax and bring your awareness inward.",
            "Prepare to connect with your body’s natural hunger signals.",
          ],
          audio: "hypnosis/24mindful.mp3",
        },
      ],
    },
    {
      workoutId: "2",
      title: "Healthy Eating Habits and Food Choices",
      image: "hypnosis/3.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Choosing Nutritious Foods Over Processed Options",
          duration: 7,
          instructions: [
            "Find a relaxing position, either sitting or lying down.",
            "Breathe deeply, releasing any tension with each exhale.",
            "Allow yourself to connect with the joy of nourishing foods.",
          ],
          audio: "hypnosis/31choosing.mp3",
        },
        {
          id: "1",
          title: "Portion Control and Satisfaction with Smaller Servings",
          duration: 6,
          instructions: [
            "Settle comfortably and take deep breaths to calm your mind.",
            "Let go of any stress, tuning into your body’s natural signals.",
            "Prepare to feel satisfied and content with balanced portions.",
          ],
          audio: "hypnosis/32portion.mp3",
        },
        {
          id: "2",
          title: "Creating Positive Associations with Healthy Foods",
          duration: 7,
          instructions: [
            "Relax in a comfortable position, allowing your eyes to close.",
            "Breathe in deeply, exhaling tension with each slow breath.",
            "Prepare to embrace the pleasure of healthy, nourishing foods.",
          ],
          audio: "hypnosis/33creating.mp3",
        },
        {
          id: "3",
          title: "Visualizing Healthy Meal Choices",
          duration: 6,
          instructions: [
            "Sit back comfortably and gently close your eyes.",
            "Take slow, calming breaths, releasing all distractions.",
            "Visualize yourself feeling joy in making healthy choices.",
          ],
          audio: "hypnosis/34visualizing.mp3",
        },
      ],
    },
    {
      workoutId: "3",
      title: "Exercise and Physical Activity Motivation",
      image: "hypnosis/4.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Finding Joy and Motivation in Movement",
          duration: 7,
          instructions: [
            "Relax into a comfortable position and close your eyes.",
            "Take a deep breath, releasing any tension with your exhale.",
            "Allow yourself to connect with the joy of moving your body.",
          ],
          audio: "hypnosis/41finding.mp3",
        },
        {
          id: "1",
          title: "Visualizing an Active, Energized Self",
          duration: 7,
          instructions: [
            "Settle comfortably and allow your eyes to close gently.",
            "Take calming breaths, releasing any stress or tension.",
            "Prepare to visualize yourself feeling energized and active.",
          ],
          audio: "hypnosis/42energized.mp3",
        },
        {
          id: "2",
          title: "Overcoming Barriers to Exercise",
          duration: 7,
          instructions: [
            "Sit or lie in a comfortable position, relaxing fully.",
            "Close your eyes and take deep breaths, releasing stress.",
            "Allow yourself to connect with your inner strength to move.",
          ],
          audio: "hypnosis/43overcoming.mp3",
        },
        {
          id: "3",
          title: "Building Stamina and Enjoyment in Physical Activity",
          duration: 6,
          instructions: [
            "Sit back comfortably and close your eyes gently.",
            "Let each breath calm you, releasing any lingering stress.",
            "Prepare to experience the joy of growing strength and stamina.",
          ],
          audio: "hypnosis/44stamina.mp3",
        },
      ],
    },
    {
      workoutId: "4",
      title: "Long-Term Habit Reinforcement and Maintenance",
      image: "hypnosis/5.png",
      macros: {
        duration: 20,
        numExercises: 4,
      },
      exercises: [
        {
          id: "0",
          title: "Creating a Future Vision of the New You",
          duration: 7,
          instructions: [
            "Find a comfortable position and close your eyes.",
            "Take slow, calming breaths, relaxing with each exhale.",
            "Allow yourself to connect with a vision of your best self.",
          ],
          audio: "hypnosis/51creating.mp3",
        },
        {
          id: "1",
          title: "Staying Committed to Long-Term Health Goals",
          duration: 7,
          instructions: [
            "Settle into a comfortable position and close your eyes.",
            "Take deep breaths, releasing any stress or distractions.",
            "Prepare to connect with your commitment to lasting wellness.",
          ],
          audio: "hypnosis/52staying.mp3",
        },
        {
          id: "2",
          title: "Dealing with Setbacks and Returning to Routine",
          duration: 6,
          instructions: [
            "Relax comfortably and close your eyes gently.",
            "Breathe deeply, letting go of any self-doubt or worry.",
            "Prepare to handle setbacks with calm and resilience.",
          ],
          audio: "hypnosis/53setbacks.mp3",
        },
        {
          id: "3",
          title: "Continued Self-Motivation for Lifelong Health",
          duration: 6,
          instructions: [
            "Settle comfortably, allowing your eyes to close gently.",
            "Breathe in deeply, letting out tension with each exhale.",
            "Connect with your motivation for lasting health and happiness.",
          ],
          audio: "hypnosis/54continued.mp3",
        },
      ],
    },
  ],
};
