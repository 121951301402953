import styled from "styled-components";
import { configValues } from "../../config-values";

const TopContent = styled.div`
  display: flex;
`;

const Title = styled.h1`
  font-size: 32px; /* Adjust the size as needed */
  color: ${configValues.project.styles.content.cardTitleColor};
  margin: 0;
`;

export const WorkoutsTopContent = () => {
  return (
    <TopContent>
      {configValues.projectName === "hypno" && <Title>Hypnosis</Title>}
      {configValues.projectName !== "hypno" && <Title>Workouts</Title>}
    </TopContent>
  );
};
