import "rmc-picker-scroll/assets/index.css";
import { useNavigate, useParams } from "react-router-dom";
import { BackHeader } from "../../components/nav/BackHeader";
import { capitalize } from "lodash";
import styled from "styled-components";
import { BottomContainer } from "../../components/nav/BottomContainer";
import { IProfile } from "../../types/profile";
import { ChangeEvent, useState } from "react";
import { SettingsItem } from "../../components/items/SettingsItem";
import { Checkbox } from "../../components/inputs/Checkbox";
import { Colors } from "../../styles/global";
import { useProfile } from "../../state/profile";
import { LoaderSvg } from "../../assets/svg/LoaderSvg";
import { IAppError, getErrors } from "../../utils/formErrors";
import { InfoItem } from "../../components/items/InfoItem";
import { v } from "../../utils/validations";
import Picker from "react-mobile-picker";
import { weights } from "../../assets/constants/weight";
import { convertBackToStr, parseOutKg } from "../../utils/weight";
import { heights } from "../../assets/constants/height";
import { convertHeightToStr, parseOutCm } from "../../utils/height";
import { configValues } from "../../config-values";

const Container = styled.div`
  height: 100vh;
  background: ${configValues.project.styles.content.userContentBackground};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 0 1rem;
  overflow: hidden;
  padding-top: 90px;
  max-height: 100%;
  flex-direction: column;
`;

const Label = styled.label`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${configValues.project.styles.content.cardTextColor};
`;

const InputContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 11px 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border: none;
  background-color: ${configValues.project.inputBgColor};
  border: 0.125rem solid ${configValues.project.inputBorderColor};
  color: ${configValues.project.inputFontColor};
`;

const InputError = styled.span`
  font-size: 12px;
  padding-left: 0.25rem;
  color: ${Colors.fontError};
`;

const Button = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${configValues.project.styles.button.fontColor};
  background: ${configValues.project.styles.button.background};
`;

const Section = styled.div<{ bg?: string }>`
  display: flex;
  margin-top: 1rem;
  border-radius: 12px;
  flex-direction: column;
  background: ${(p) =>
    p.bg ?? configValues.project.styles.content.cardBackground};
  border: 0.0625rem solid ${configValues.project.styles.content.cardBorderColor};
  box-shadow: ${configValues.project.styles.content.cardShadow};
`;

const Separator = styled.div`
  height: 1px;
  width: auto;
  margin: 0 1rem;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const EditView = () => {
  const { type } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<IAppError>({});
  const { profile: initialProfile, update } = useProfile();
  const [profile, setProfile] = useState<any>({
    ...initialProfile,
    goal: convertBackToStr(initialProfile.goal),
    weight: convertBackToStr(initialProfile.weight),
    height: convertHeightToStr(initialProfile.height),
  });

  const updateProfile =
    (key: keyof IProfile) =>
    (value: ChangeEvent<HTMLInputElement> | string) => {
      const val = typeof value === "string" ? value : value.target.value;

      setProfile({ ...profile, [key]: val });
    };

  const onSubmit = async () => {
    try {
      setErrors({});
      setLoading(true);
      await v.profile.validateSync(profile, { abortEarly: false });

      await update({
        ...profile,
        goal: parseOutKg(profile.goal),
        height: parseOutCm(profile.height),
        weight: parseOutKg(profile.weight),
      });
      navigate(-1);
    } catch (e) {
      console.log(e);
      setErrors(getErrors(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <BackHeader title={`Edit ${capitalize(type)}`} />
      <Content>
        {errors.error && <InfoItem error={true} info={errors.error} />}
        {type === "name" && (
          <>
            <InputContainer>
              <Label>Name</Label>
              <Input
                value={profile.name}
                placeholder="Name"
                onChange={updateProfile("name")}
              />
              {errors?.name && <InputError>{errors.name}</InputError>}
            </InputContainer>

            <div style={{ height: "1rem" }} />
            <InputContainer>
              <Label>Surname</Label>
              <Input
                value={profile.surname}
                placeholder="Surname"
                onChange={updateProfile("surname")}
              />
              {errors?.surname && <InputError>{errors.surname}</InputError>}
            </InputContainer>
          </>
        )}
        {type === "units" && (
          <Section>
            <SettingsItem
              title="Metric"
              onClick={() => updateProfile("units")("metric")}
              icon={<Checkbox active={profile.units === "metric"} />}
            />
            <Separator />
            <SettingsItem
              title="Imperial"
              onClick={() => updateProfile("units")("imperial")}
              icon={<Checkbox active={profile.units === "imperial"} />}
            />
          </Section>
        )}
        {type === "height" && (
          <Picker
            value={profile as any}
            height={window.screen.height - 200}
            onChange={(v) => {
              updateProfile("height")(v.height);
            }}
          >
            <Picker.Column name="height">
              {heights.map((option) => (
                <Picker.Item key={option} value={option}>
                  <span
                    style={{
                      color: configValues.project.styles.content.cardTextColor,
                    }}
                  >
                    {option}
                  </span>
                </Picker.Item>
              ))}
            </Picker.Column>
          </Picker>
        )}
        {type === "weight" && (
          <Picker
            value={profile as any}
            height={window.screen.height - 200}
            onChange={(v) => {
              updateProfile("weight")(v.weight);
            }}
          >
            <Picker.Column name="weight">
              {weights.map((option) => (
                <Picker.Item key={option} value={option}>
                  <span
                    style={{
                      color: configValues.project.styles.content.cardTextColor,
                    }}
                  >
                    {option}
                  </span>
                </Picker.Item>
              ))}
            </Picker.Column>
          </Picker>
        )}
        {type === "goal" && (
          <Picker
            value={profile as any}
            height={window.screen.height - 200}
            onChange={(v) => {
              updateProfile("goal")(v.goal);
            }}
          >
            <Picker.Column name="goal">
              {weights.map((option) => (
                <Picker.Item key={option} value={option}>
                  <span
                    style={{
                      color: configValues.project.styles.content.cardTextColor,
                    }}
                  >
                    {option}
                  </span>
                </Picker.Item>
              ))}
            </Picker.Column>
          </Picker>
        )}
      </Content>
      <BottomContainer>
        <Button onClick={onSubmit}>{loading ? <LoaderSvg /> : "Update"}</Button>
      </BottomContainer>
    </Container>
  );
};
