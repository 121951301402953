import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { BackSvg } from "../../assets/svg/BackSvg";
import { useWorkoutPlan } from "../../state/workoutPlan";
import { configValues } from "../../config-values";

const BackButton = styled.button`
  top: 1rem;
  left: 1rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  position: fixed;
  border-radius: 50%;
  background: white;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const WorkoutImageContainer = styled.div`
  width: 100%;
  height: 5vh;
  object-fit: cover;
`;

const WorkoutImage = styled.img`
  width: 100%;
  max-height: 30vh;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 0 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 2rem;
  font-weight: 700;
`;

const Details = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  margin-bottom: 16px;
`;

const Disclaimer = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 0.5rem;
`;

const InstructionsList = styled.ul`
  padding: 0;
  list-style: none;
`;

const InstructionItem = styled.li`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
`;

const Card = styled.div`
  display: flex;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  background: #ffffff;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

export const WorkoutExerciseView = () => {
  const navigate = useNavigate();
  const { workoutId, exerciseId } = useParams();
  const { workouts } = useWorkoutPlan();
  const relatedWorkout = workouts[workoutId as any];

  if (!relatedWorkout) {
    // Handle the case where the workout is not found
    return <div>Workout not found.</div>;
  }

  const exercise = relatedWorkout.exercises.find((x) => x.id === exerciseId);
  if (!exercise) {
    // Handle the case where the exercise is not found
    return <div>Exercise not found.</div>;
  }

  return (
    <>
      <BackButton onClick={() => navigate(-1)}>
        <BackSvg />
      </BackButton>
      {!exercise.image && <WorkoutImageContainer />}
      {exercise.image && (
        <WorkoutImage src={exercise.image} alt={exercise.title} />
      )}
      <Content>
        <Title>{exercise.title}</Title>
        <Details>
          {exercise.duration} min
          {exercise.sets && exercise.reps && (
            <>
              {" "}
              · {exercise.sets} sets X {exercise.reps} reps
            </>
          )}
        </Details>
        {exercise.audio && <audio src={`/audio/${exercise.audio}`} controls />}
        <SectionTitle>How to do</SectionTitle>
        <InstructionsList>
          {exercise.instructions?.map((instruction) => (
            <InstructionItem key={instruction}>{instruction}</InstructionItem>
          ))}
        </InstructionsList>
        <Card>
          <Disclaimer>
            {configValues.projectName === "hypno" && (
              <Disclaimer>
                * Your hypnosis practice might look different than the picture
                since this specific practive is adjusted to your specific goals
              </Disclaimer>
            )}
            {configValues.projectName !== "hypno" && (
              <Disclaimer>
                * Your exercise might look different than the picture since this
                specific exercise is adjusted to your specific goals
              </Disclaimer>
            )}
          </Disclaimer>
        </Card>
      </Content>
    </>
  );
};
