import { PropsWithChildren, FC } from "react";
import styled from "styled-components";
import { configValues } from "../../config-values";
import { WorkoutCarouselItem } from "../items/WorkoutCarouselItem";

const Container = styled.div`
  gap: 0.5rem;
  display: flex;
  margin-bottom: 1rem;
  border-radius: 12px;
  background: ${configValues.project.styles.content.cardBackground};
  border: 0.0625rem solid ${configValues.project.styles.content.cardBorderColor};
  flex-direction: column;
  align-items: flex-start;
  box-shadow: ${configValues.project.styles.content.cardShadow};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled.h2`
  font-size: 24px;
  color: ${configValues.project.styles.content.cardTitleColor};
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.2);
`;

interface Props {
  workoutId: string;
}

export const DayWorkoutCard: FC<PropsWithChildren<Props>> = ({ workoutId }) => (
  <Container>
    <Header>
      <Title>
        {configValues.projectName === "hypno"
          ? "Hypnosis of the day"
          : "Workout of the day"}
      </Title>
    </Header>
    <Divider />
    <WorkoutCarouselItem id={workoutId} goalCard={true} />
  </Container>
);
