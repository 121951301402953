import { BackHeader } from "../../components/nav/BackHeader";
import styled from "styled-components";
import { useState } from "react";
import { Colors } from "../../styles/global";
import { InfoItem } from "../../components/items/InfoItem";
import { validateEmail } from "../../utils/validations";
import { Api } from "../../apis/api";
import { useNavigate } from "react-router-dom";
import { configValues } from "../../config-values";

const Container = styled.div`
  height: 100vh;
  background: ${configValues.project.styles.content.background};
`;

const Content = styled.div`
  flex: 1;
  gap: 1rem;
  display: flex;
  padding: 0 2rem;
  padding-top: 6rem;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 11px 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  border: none;
  background-color: ${configValues.project.inputBgColor};
  border: 0.125rem solid ${configValues.project.inputBorderColor};
  color: ${configValues.project.inputFontColor};
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  color: white;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${configValues.project.styles.button.fontColor};
  background: ${configValues.project.styles.button.background};
`;

export const ForgotView = () => {
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = async () => {
    try {
      if (validateEmail(email)) {
        await Api.resetPassword(email);
        setSent(true);
        setTimeout(() => navigate(-1), 3000);
      }
    } catch (e) {
      Api.reportError(e, {
        fn: "onSubmit",
        email: email,
      });
    }
  };

  const message = sent
    ? "We will send link to reset your password to email provided"
    : `Enter email address you used to log in to the ${configValues.project.marketedTitle} app`;

  return (
    <Container>
      <BackHeader title="Forgot password" />
      <Content>
        <InfoItem mb="1rem" info={message} />
        <Input
          value={email}
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button onClick={onSubmit}>Reset Password</Button>
      </Content>
    </Container>
  );
};
