import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../styles/global";
import { configValues } from "../../config-values";
import { MeditationsSvg } from "../../assets/svg/MeditationsSvg";
import { WorkoutsSvg } from "../../assets/svg/WorkoutsSvg";

const Tab = styled(Link)`
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  padding: 10px;
  text-decoration: none;
  flex-direction: column;
  color: rgba(34, 34, 34, 0.4);
  font-size: 14px;

  &.active {
    color: ${Colors.primary};
  }

  svg {
    margin-bottom: 5px;
  }
`;

export const WorkoutsTab = () => {
  const location = useLocation();
  const includes = (str: string) => location.pathname.includes(str);
  return (
    <Tab to="/workouts" className={includes("/workouts") ? "active" : ""}>
      {configValues.projectName === "hypno" && <MeditationsSvg />}
      {configValues.projectName === "hypno" && <span>Hypnosis</span>}
      {configValues.projectName !== "hypno" && <WorkoutsSvg />}
      {configValues.projectName !== "hypno" && <span>Workouts</span>}
    </Tab>
  );
};
