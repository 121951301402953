import { FC } from "react";
import styled from "styled-components";
import { configValues } from "../../config-values";

const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  box-shadow: ${configValues.project.styles.content.backHeadeBoxShadow};
  background: ${configValues.project.styles.content.userContentBackground};
`;

const TopContent = styled.div`
  display: flex;
  padding: 1rem;
`;

const Title = styled.h1`
  font-size: 32px; /* Adjust the size as needed */
  color: ${configValues.project.styles.content.cardTitleColor};
  margin: 0;
`;

interface Props {
  title: string;
}

export const LeftHeader: FC<Props> = ({ title }) => (
  <Container>
    <TopContent>
      <Title>{title}</Title>
    </TopContent>
  </Container>
);
