import styled from "styled-components";
import { configValues } from "../../config-values";

export const BottomContainer = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  padding: 1rem;
  display: flex;
  position: fixed;
  flex-direction: column;
  box-shadow: ${configValues.project.styles.content.backHeadeBoxShadow};
  background: ${configValues.project.styles.content.userContentBackground};
`;
