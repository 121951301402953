import { FC, useState } from "react";
import { ActionSheet } from "./ActionSheet";
import styled from "styled-components";
import Picker from "react-mobile-picker";
import { Colors } from "../../styles/global";
import { LoaderSvg } from "../../assets/svg/LoaderSvg";
import { weights } from "../../assets/constants/weight";
import { convertBackToStr, parseOutKg } from "../../utils/weight";
import { configValues } from "../../config-values";

const Container = styled.div`
  gap: 2rem;
  display: flex;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.span`
  color: #666;
  max-width: 100%;
  font-size: 1rem;
  text-align: center;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${configValues.project.styles.button.fontColor};
  background: ${configValues.project.styles.button.background};
`;

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: number) => void;
  loading?: boolean;
  currentWeight?: number;
}

export const WeightSheet: FC<Props> = ({
  title,
  isOpen,
  onClose,
  onSave,
  loading,
  currentWeight,
}) => {
  const [weight, setWeight] = useState({
    weight: convertBackToStr(currentWeight),
  });

  const submit = () => {
    const weightNum = parseOutKg(weight.weight);
    onSave(weightNum);
  };

  return (
    <ActionSheet isOpen={isOpen} onClose={onClose}>
      <Container>
        <Title>{title}</Title>
        <Picker
          value={weight}
          style={{ width: "100%" }}
          onChange={(v) => setWeight(v)}
          height={window.screen.height - 400}
        >
          <Picker.Column name="weight">
            {weights.map((option) => (
              <Picker.Item key={option} value={option}>
                {option}
              </Picker.Item>
            ))}
          </Picker.Column>
        </Picker>
        <Button onClick={submit}>{loading ? <LoaderSvg /> : "Save"}</Button>
      </Container>
    </ActionSheet>
  );
};
