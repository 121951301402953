import { FC } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/global";
import { IProfile } from "../../types/profile";
import { getFullName, getInitials } from "../../utils/profile";
import { configValues } from "../../config-values";

const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 30vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
  border: 0.0625rem solid ${configValues.project.styles.content.cardBorderColor};
  background: ${configValues.project.styles.content.profileBackground};
`;

const Avatar = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  margin: 1rem 0;
  font-weight: 700;
  font-size: 1.5rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: ${configValues.project.styles.content.cardTitleColor};
  border: 3px solid ${configValues.project.styles.content.cardBorderColor};
  background: ${configValues.project.styles.content.userContentBackground};
`;

const Title = styled.h1`
  margin: 0;
  font-size: 1rem;
  color: ${configValues.project.styles.content.cardTitleColor};
`;

const UserName = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 1.5rem;
  color: ${configValues.project.styles.content.cardTitleColor};
`;

export const ProfileHeader: FC<IProfile> = (profile) => {
  return (
    <Container>
      <Title>My Profile</Title>
      <Avatar>{getInitials(profile)}</Avatar>
      <UserName>{getFullName(profile)}</UserName>
    </Container>
  );
};
