import styled from "styled-components";
import { ArrowLongRightSvg } from "../../assets/svg/ArrowLongRight";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "../../styles/global";
import { useProfile } from "../../state/profile";
import { useEffect } from "react";
import { configValues } from "../../config-values";

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  padding-top: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.bg};
`;

const TopContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const ImageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-width: 600px;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Buttons = styled.div`
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 1rem;
  display: flex;
  padding-bottom: 2rem;
  flex-direction: column;
`;

const ButtonLink = styled(Link)`
  box-sizing: border-box;
  width: auto;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  align-items: center;
  text-decoration: none;
  color: ${configValues.project.styles.button.fontColor};
  background: ${configValues.project.styles.button.background};
`;

const Vertical = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  flex-direction: column;
`;

const Label = styled.label`
  box-sizing: border-box;
  margin: 0;
  opacity: 0.8;
  font-size: 14px;
  font-weight: 600;
`;

export const Main = () => {
  const { profile } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile) {
      if (profile._id && profile.active) {
        navigate("/explore");
      } else if (profile._id) {
        alert("Subscription expired");
      }
    }
  }, [profile, navigate]);
  const logo = require(`../../assets/images/${configValues.project.logo}`);

  const {
    LogoSvg,
  } = require(`../../assets/svg/Logos/${configValues.project.logoSvg}`);

  return (
    <Container>
      <TopContainer>
        <LogoSvg />
        <ImageContainer>
          <Image
            alt="Hero"
            src={logo}
            style={{
              borderRadius: configValues.project.styles.logo.borderRadius,
            }}
          />
        </ImageContainer>
      </TopContainer>
      <Buttons>
        <ButtonLink
          to={
            configValues.project.inReview
              ? `${configValues.websiteUrl}register-app`
              : `${configValues.websiteUrl}quiz`
          }
        >
          <Vertical>
            <Label>New to {configValues.project.marketedTitle}?</Label>
            <span>Register</span>
          </Vertical>
          <ArrowLongRightSvg />
        </ButtonLink>
        <ButtonLink to="/login">
          <Vertical>
            <Label>Already have an account?</Label>
            <span>Login</span>
          </Vertical>
          <ArrowLongRightSvg />
        </ButtonLink>
      </Buttons>
    </Container>
  );
};
