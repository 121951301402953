import { FC } from "react";
import styled from "styled-components";
import { WorkoutCard } from "../cards/WorkoutCard";
import { configValues } from "../../config-values";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface Props {
  id: string;
  goalCard?: boolean;
}

export const WorkoutCarouselItem: FC<Props> = ({ id, goalCard = false }) => {
  return (
    <Container>
      <WorkoutCard workoutId={id} goalCard={goalCard} />
    </Container>
  );
};
