import { FC, useState } from "react";
import { ActionSheet } from "./ActionSheet";
import styled from "styled-components";
import { Counter } from "../inputs/Counter";
import { Colors } from "../../styles/global";
import { LoaderSvg } from "../../assets/svg/LoaderSvg";
import { configValues } from "../../config-values";

const Container = styled.div`
  gap: 2rem;
  display: flex;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.span`
  color: #666;
  max-width: 100%;
  font-size: 1rem;
  text-align: center;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${configValues.project.styles.content.waterButtonTextColor};
  background: ${configValues.project.styles.content.waterButtonBackground};
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: number) => void;
  loading?: boolean;
  currentIntake?: number;
}

export const WaterSheet: FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  loading,
  currentIntake = 2000,
}) => {
  const [intake, setIntake] = useState(currentIntake);

  const submit = () => {
    onSave(intake);
  };

  return (
    <ActionSheet isOpen={isOpen} onClose={onClose}>
      <Container>
        <Title>Modify your daily water intake goal</Title>
        <Counter
          min={500}
          step={100}
          unit="ml"
          initial={intake}
          onChange={(v) => setIntake(v)}
        />
        <Button onClick={submit}>{loading ? <LoaderSvg /> : "Save"}</Button>
      </Container>
    </ActionSheet>
  );
};
