import { FC } from "react";
import styled from "styled-components";
import { EditSvg } from "../../assets/svg/EditSvg";
import { configValues } from "../../config-values";

const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 10;
  box-shadow: ${configValues.project.styles.content.backHeadeBoxShadow};
  background: ${configValues.project.styles.content.userContentBackground};
`;

const TopContent = styled.div`
  display: flex;
  padding: 1rem;
`;

const Title = styled.h1`
  font-size: 24px; /* Adjust the size as needed */
  color: ${configValues.project.styles.content.cardTitleColor};
  margin: 0;
`;

const EditButton = styled.button`
  margin-left: auto; /* Pushes the button to the right */
  background: none;
  border: none;
  font-size: 14px;
  line-height: 20px;
  color: ${configValues.project.styles.content.cardTitleColor};
  font-weight: 600;
  cursor: pointer;

  svg {
    fill: ${configValues.project.styles.content.cardTitleColor};
    margin-bottom: -2px;
    margin-right: 5px;
  }
`;

interface Props {
  onEdit: () => void;
}

export const ShoppingListHeader: FC<Props> = ({ onEdit }) => (
  <Container>
    <TopContent>
      <Title>Shopping List</Title>
      <EditButton onClick={onEdit}>
        <EditSvg />
        EDIT
      </EditButton>
    </TopContent>
  </Container>
);
